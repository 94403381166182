<template>
  <div class="py-5">
    <b-container ref="salesRoom">
      <h3 class="sales-room-title text-left mt-3 mb-5">Ubicación</h3>
      <b-row>
        <b-col cols="12" md="5" xl="5">
          <div class="background-image sales-room-image">
            <iframe
              :src="data.address_iframe"
              width="100%"
              height="500"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </b-col>
        <b-col
          cols="12"
          md="5"
          xl="5"
          class="my-auto order-1 order-md-0"
          v-if="data"
        >
          <h2 class="title text-left my-5 my-md-0 my-xl-0 mb-xl-4 mb-md-4">
            Sala de ventas
          </h2>
          <b-row
            :class="{ 'sales-room-item-bolder': key == 0 }"
            v-for="(item, key) in data.sales_room_infos"
            :key="'sales-room-info' + key"
          >
            <b-col cols="1" md="1" xl="1">
              <b-img class="mr-4" :src="item.icon.image_url"></b-img>
            </b-col>
            <b-col cols="11" md="11" xl="11">
              <p class="subtitle text-left mt-0">
                <a :href="item.link">{{ item.text }}</a>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col xl="12" class="mt-3">
              <div class="float-left">
                <a
                  :href="`https://api.whatsapp.com/send?phone=${data.whatsapp}`"
                  target="_blank"
                  ><b-button variant="transparent" class="p-0 svv-wsp-button"
                    ><b-img
                      :src="
                        require('@/assets/images/whatsapp.svg')
                      " /></b-button
                ></a>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          md="2"
          xl="2"
          class="my-auto order-0 order-md-1"
          v-if="data"
        >
          <b-img
            v-if="
              images.find(
                (potrait) =>
                  potrait.name == data.sales_room_infos[0].text.trim()
              )
            "
            :src="
              images.find(
                (potrait) =>
                  potrait.name == data.sales_room_infos[0].text.trim()
              ).src
            "
            class="w-100 rounded-circle mt-5 mt-md-0"
          ></b-img>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SalesRoom",
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      images: [
        {
          name: "Ana Patricia Aravena",
          src: require("@/assets/images/projects/details/sales-room/potraits/ana-patricia.png"),
        },
        {
          name: "Andrea Virreira",
          src: require("@/assets/images/projects/details/sales-room/potraits/Andrea-Virreira.png"),
        },
        {
          name: "Elsa Vallejos",
          src: require("@/assets/images/projects/details/sales-room/potraits/Elsa-Vallejos.png"),
        },
        {
          name: "Wendy Navarro",
          src: require("@/assets/images/projects/details/sales-room/potraits/Wendy-Navarro.png"),
        },
        {
          name: "Erika Nuñez",
          src: require("@/assets/images/projects/details/sales-room/potraits/Erika-Nuñez.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.sales-room-image {
  height: 500px !important;
}
.sales-room-item-bolder {
  font-weight: 700;
  font-size: 18px;
}
.sales-room-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 31px;
  line-height: 40px;
  /* identical to box height, or 129% */

  letter-spacing: 0.1px;
  text-transform: uppercase;
}
</style>
